.print {
  padding: 50px;
}

.print > div > p {
  font-size: 30px;
}

/* @media print {
  body,
  html {
    margin: 0;
  }
  .print-preview {
    width: 21cm;
    margin: 0;
  }
  @page {
    size: 0mm;
    width: 500mm;
    margin: 0mm;
  }
} */
